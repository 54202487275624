import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useIntl } from "gatsby-plugin-react-intl";

import QrImage from './QrImage';
import ImgBanner from '../assets/images/img_banner.svg';

const Banner = (props) => {
    const intl = useIntl();

    return (
        <div id="start">
            <section id="qr-banner">
                <Container>
                    <Row className="align-items-center">
                        <Col lg={6}>
                            <p><strong>{intl.formatMessage({ id: "BANNER.TEXT_1" }).toUpperCase()}</strong></p>
                            <h1>{intl.formatMessage({ id: "BANNER.TEXT_2" })}</h1>
                            <p className="lead">{intl.formatMessage({ id: "BANNER.TEXT_3" })}</p>
                        </Col>
                        <Col lg={6}>
                            <QrImage
                                src={ImgBanner}
                                ariaLabel={intl.formatMessage({ id: "BANNER.TEXT_2" })}
                            />
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )
}

export default Banner;